<template>
  <el-form ref="appForm" v-loading="loadingDetail.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
    <ics-page-inner title="尽调报告">
      <el-row :gutter="10" type="flex" justify="center" align="middle">
        <el-col :span="12" :push="5">
          <el-form-item label="统一社会信用代码" label-width="170px">
            <p>{{ utils.isEffectiveCommon(companyDetail.socialNo) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业全称" label-width="170px">
            <p>{{ utils.isEffectiveCommon(companyDetail.companyName) }}</p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80" type="flex" justify="center" align="middle">
        <el-col :span="12">
          <el-form-item label="被列入严重违法失信企业名单" label-width="245px">
            <el-radio-group v-model="appForm.trustworthy" :disabled="!supplyView()">
              <el-radio :label="0">
                是
              </el-radio>
              <el-radio :label="1">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80" type="flex" justify="center" align="middle">
        <el-col :span="12">
          <el-form-item label="被列入经营异常名单" label-width="245px">
            <el-radio-group v-model="appForm.abnormal" :disabled="!supplyView()">
              <el-radio :label="0">
                是
              </el-radio>
              <el-radio :label="1">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80" type="flex" justify="center" align="middle">
        <el-col :span="12">
          <el-form-item label="被列为被执行人" prop="implementer" label-width="245px">
            <el-radio-group v-model="appForm.implementer" :disabled="!supplyView()">
              <el-radio :label="0">
                是
              </el-radio>
              <el-radio :label="1">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80" type="flex" justify="center" align="middle">
        <el-col :span="14" :push="2" style="margin-left: 40px;">
          <el-form-item label="尽调报告" prop="reportUrlFiles">
            <p v-if="supplyView()">
              <debounce-button type="primary" @click="clickDialog">
                点击上传
              </debounce-button>
            </p>
            <p>
              <a href="javascript:" class="text-btn" @click="utils.downloadP('firm', appForm.reportUrl)">{{ appForm.reportName }}</a>
            </p>
          </el-form-item>
        </el-col>
      </el-row>
      <br>
      <ics-button-inner v-if="supplyView()" :loading="loadingDetail.submit" submit-title="下一步" cancel-title="返回" @submit="submitForms" />
      <ics-common-upload-inner :upload-dialog="dialog.upload" bucket="firm" @onSuccessFile="onSuccessFile" />
    </ics-page-inner>
  </el-form>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCommonUploadInner from '@/components/common-upload-inner'
export default {
  name: 'IcsInvestigationInner',
  components: {IcsCommonUploadInner},
  mixins: [routeEnterMixin],
  props: {
    loadingDetail: {
      type: Object,
      default() {
        return {}
      }
    },
    companyDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    investigationInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      appForm: {
        trustworthy: 1,
        abnormal: 1,
        implementer: 1,
        reportName: '',
        reportUrl: ''
      },
      dialog: {
        upload: {
          visible: false,
          title: '上传调研报告'
        }
      },
      rules: {
        reportUrlFiles: [
          { required: false, message: '请上传尽调报告', trigger: 'change' }
        ],
      }
    }
  },
  watch: {
    investigationInfo: 'initData'
  },
  created () {
    if (this.supplyView()) {
    }
  },
  methods: {
    initData(value) {
      this.appForm = Object.assign(this.appForm, this.utils.filterTrimValue(value))
    },
    supplyView () {
      if (this.$route.params.editMode === 'supplement') {
        return true
      } else {
        return false
      }
    },
    clickDialog () {
      this.dialog.upload.visible = true
    },
    onSuccessFile(response) {
      const data = this._.cloneDeep(response.data)
      this.appForm.reportName = data.name
      this.appForm.reportUrl = data.path
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.$emit('nextStep', this.appForm)
      })
    }
  }
}
</script>

<style scoped>

</style>
